import { cronosTokens, serializeToken } from '@pancakeswap/tokens'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
  {
    pid: null,
    lpSymbol: 'SCRATCH-CRO LP',
    lpAddress: '0x4794CA422bEaECa52cF20145ac15D18C640F6037',
    token: cronosTokens.scratch,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'VVS-CRO LP',
    lpAddress: '0xbf62c67eA509E86F07c8c69d0286C0636C50270b',
    token: cronosTokens.vvs,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'MTD-USDC LP',
    lpAddress: '0xc9e0f06E28575A438Cb0847Bc748B00D208dBc95',
    token: cronosTokens.mtd,
    quoteToken: cronosTokens.usdc,
  },
  {
    pid: null,
    lpSymbol: 'MMF-CRO LP',
    lpAddress: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
    token: cronosTokens.mmf,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'CROID-USDC LP',
    lpAddress: '0x05032D7B0E329F8E88CAF9563432F142244356bA',
    token: cronosTokens.croid,
    quoteToken: cronosTokens.usdc,
  },
  {
    pid: null,
    lpSymbol: 'FER-USDC LP',
    lpAddress: '0x564f1ABDde386eD58bA5907d91F1741fE275e8c2',
    token: cronosTokens.fer,
    quoteToken: cronosTokens.usdc,
  },
  {
    pid: null,
    lpSymbol: 'TONIC-USDC LP',
    lpAddress: '0x2f12D47Fe49B907d7a5Df8159C1CE665187F15c4',
    token: cronosTokens.tonic,
    quoteToken: cronosTokens.usdc,
  },
  {
    pid: null,
    lpSymbol: 'MIMAS-CRO LP',
    lpAddress: '0xf56FDfeeF0Ba3de23DaB13a85602bd7BF135E80f',
    token: cronosTokens.mimas,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'WARZ-CRO LP',
    lpAddress: '0xDdCBee0F1AD911D6644A7361473Dfaef2048165d',
    token: cronosTokens.warz,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'ADA-CRO LP',
    lpAddress: '0x28cC282E101789135aCe6CD408231B651cE53b53',
    token: cronosTokens.ada,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'ATOM-CRO LP',
    lpAddress: '0x9e5bd780dff875Dd85848a65549791445AE25De0',
    token: cronosTokens.atom,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'SHIB-CRO LP',
    lpAddress: '0xc9eA98736dbC94FAA91AbF9F4aD1eb41e7fb40f4',
    token: cronosTokens.shib,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'DOGE-CRO LP',
    lpAddress: '0x2A560f2312CB56327AD5D65a03F1bfEC10b62075',
    token: cronosTokens.doge,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'MATIC-CRO LP',
    lpAddress: '0x18AE87AE14279D42fCdde542c7Faa65768434024',
    token: cronosTokens.matic,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'QNT-CRO LP',
    lpAddress: '0x790259C4D31c6BfBa9386FEd778c94DD1BD09FbE',
    token: cronosTokens.qnt,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'ARGO-USDC LP',
    lpAddress: '0xDcBe2035a7b24f7AA479BFf3cCA46d2F5f014B74',
    token: cronosTokens.argo,
    quoteToken: cronosTokens.usdc,
  },
  {
    pid: null,
    lpSymbol: 'VNO-USDC LP',
    lpAddress: '0x9CfB6901BFEc670C756fbE0a724d4Fe95372bBAf',
    token: cronosTokens.vno,
    quoteToken: cronosTokens.usdc,
  },
  {
    pid: null,
    lpSymbol: 'FIRA-CRO LP',
    lpAddress: '0x689E7BA5170001aC887965a5Db1C9Ce932c93462',
    token: cronosTokens.fira,
    quoteToken: cronosTokens.wcro,
  },
  {
    pid: null,
    lpSymbol: 'XTC-CRO LP',
    lpAddress: '0x4339Ad632438661Db56B4dd5ad82279de743676f',
    token: cronosTokens.xtc,
    quoteToken: cronosTokens.wcro,
  },
].map((p) => ({ ...p, token: serializeToken(p.token), quoteToken: serializeToken(p.quoteToken) }))

export default priceHelperLps
